<template lang="pug">
    layout_page
        section.header.projects_header.font_smooth
            .bg.image
            .column.flex_content-vertical.ai_center.jc_center
                .structure
                .margin-bottom--md.anime.fadeInBottom.one
                    h1.text--xxxl.text--uppercase.text--ls-sm Clientes #[span &] Proyectos
                .anime.fadeInBottom.two
                    p.text--uppercase.text--ls-sm Orgullosos de participar en tu proyecto
            i.scroll_marker.anime.fadeInBottom.three
        section.projects
            .column
                .sec_title.sec_title--center.margin-bottom--xxl
                    span 01
                    h3 Proyectos
                .control-panel.margin-bottom--xxl.flex_content-horizontal.jc_center.ai_center
                    ul.filters
                        li
                            button(@click="filter('all')" :class="(currentTag == 'all') ? 'current':''").btn--filter: span Todos
                        li(v-for="service in servicesWithProjects")
                            button(@click="filter(service.name)" :class="(currentTag == service.name.toLowerCase()) ? 'current':''").btn--filter
                                span {{ service.name }}
                transition-group(name="grid_projects--item" tag="div").grid_projects.flex_content-horizontal
                    .project(v-for="project in allProjects" :key="project.pk").project.font_smooth
                        figure
                            img(:src="project.thumbnail|ress" :alt="project.thumbnail.description").project--img
                            figcaption.project--figcaption
                                .wrapper
                                    .margin-bottom--lg
                                        h4.text--uppercase.text--ls-sm {{ project.name }}
                                    slug-link(
                                        route="landpage_project"
                                        :name="project.name"
                                        :pk="project.pk"
                                    ).btn.btn--base
                                        span Ver más
</template>

<script>
import Page from '@/views/layouts/Page'
import anime from 'animejs'
import SlugLink from '@/components/SlugLink'

export default {
    name: 'page_projects',
    components: {
        layout_page: Page,
        SlugLink
    },
    data() {
        return {
            currentTag: 'all'
        }
    },
    metaInfo() {
    return {
      title: 'Proyectos',
      meta:[
        {vmid: 'dtn', name:'description', content: 'Conoce más sobre nuestros proyectos y descubre todo lo que podemos hacer.'},
        {vmid: 'kw', name:'keywords', content: 'La fragua, clientes de la fragua, proyectos de la fragua, clientes y proyectos, estructuras, grúas, armaduras, marcos para túneles'},
        {vmid: 'inm', itemprop:'name', content: 'La Fragua | Proyectos'},
        {vmid: 'idtn', itemprop:'description', content: 'Conoce más sobre nuestros proyectos y descubre todo lo que podemos hacer.'},
        {vmid: 'ptt', property:'og:title', content: 'La Fragua | Proyectos'},
        {vmid: 'purl', property:'og:url', content: 'https://lafragua.mx/proyectos'},
        {vmid: 'pdtn', property:'og:description', content: 'Conoce más sobre nuestros proyectos y descubre todo lo que podemos hacer.'},
        {vmid: 'tdtn', name:'twitter:description', content: 'Conoce más sobre nuestros proyectos y descubre todo lo que podemos hacer.'},
        {vmid: 'twtt', name:'twitter:title', content: 'La Fragua | Proyectos'}
      ]
    }
  },
    methods: {
        slugify(value) {
            value = value.toString().toLowerCase().trim()
            return value.replace(/\s+/g, '-').replace(/[^\w\-]+/g, '')
        },
        initAnimations(curtain_shown) {
            let el = this.$el
            let timeline = anime.timeline()
            setTimeout(() => {
                if (!curtain_shown) {
                    timeline.add({
                        targets: el.querySelector('section.header'),
                        scale: [0.8, 1],
                        easing: 'easeOutExpo'
                    })
                }
                timeline
                .add({
                    targets: el.querySelector('.anime.fadeInBottom.one'),
                    translateY: [30, 0],
                    opacity: [0, 1],
                    easing: 'easeOutExpo'
                })
                .add({
                    targets: el.querySelector('.anime.fadeInBottom.two'),
                    translateY: [30, 0],
                    opacity: [0, 1],
                    easing: 'easeOutExpo'
                })
                .add({
                    targets: el.querySelector('.anime.fadeInBottom.three'),
                    translateY: [30, 0],
                    opacity: [0, 1],
                    easing: 'easeOutExpo'
                })
            }, curtain_shown ? 0 : 2400)
        },
        filter(tag) {
            tag = tag.toLowerCase().trim()
            this.currentTag = tag
        }
    },
    mounted() {
        this.initAnimations(this.$store.getters.isCurtainShown)
        this.$store.commit('desactiveCurtain')
    },
    computed: {
        servicesWithProjects() {
            return this.$store.getters.servicesWithProjects
        },
        allProjects() {
            if (this.currentTag == 'all') {
                return this.$store.getters.allProjects
            } else {
                let filter = this.currentTag
                return this.$store.getters.allProjects.filter((proyect) => {
                    return proyect.tags.indexOf(filter) !== -1
                })
            }
        }
    },
    watch: {
        '$route' (to, from) {
            if (to.name == 'page_projects') {
                this.initAnimations(true)
            }
        }
    }
}
</script>